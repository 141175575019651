@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.grecaptcha-badge { visibility: hidden; }

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

#body-override {
  margin: 0
}

.gradient-svg-mvnxlogo stop {
  transition: 1s;
}

.menu-button:hover .gradient-svg-mvnxlogo > stop:last-child {
  stop-color: #8BC1FF;
}